import React from 'react'

const initialState = {
  modalIsOpen: false,
  mobileMenu: false,
  modalData: {},
  openModal: () => { },
  closeModal: () => { },
  toggleMobileMenu: () => { },
}

export const ModalContext = React.createContext(initialState)

export class ModalProvider extends React.Component {
  constructor (props) {
    super(props)
    this.state = initialState
  }

  // componentDidMount () {
  //   document.addEventListener('keydown', this.escFunction, false)
  // }

  // componentWillUnmount () {
  //   document.removeEventListener('keydown', this.escFunction, false)
  // }

  // escFunction = e => {
  //   if (e && e.keyCode === 27) { // escape key maps to keycode `27`
  //     this.toggleCart()
  //   }
  // }


  openModal = (video) => {
    const modalData = Object.assign({}, this.state.modalData, video);
    console.log('openModal:::modalData::::', modalData)

    this.setState({modalIsOpen: true})
    this.setState({ modalData: modalData })
    console.log('openModal:: modalData::', this.state.modalData)
  }

  closeModal = () => {
    this.setState({modalIsOpen: false})
    console.log('openModal::::', this.state.modalIsOpen)
  }


  toggleCart = () => {
    const { cartOpen } = this.state
    if (!cartOpen) this.setState({ cartOpen: true })
    else this.setState({ cartOpen: false })
  }


  render () {
    const { children } = this.props
    return (
      <ModalContext.Provider
        value={{
          ...this.state,
          openModal: this.openModal,
          closeModal: this.closeModal,
        }}
      >
        {children}
      </ModalContext.Provider>
    )
  }
}

export const withModalContext = Component => props => (
  <ModalContext.Consumer>
    {context => <Component {...props} modalContext={context} />}
  </ModalContext.Consumer>
)
