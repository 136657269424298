import { lighten, darken, rgba } from 'polished'

export const black = '#000'
export const white = '#fff'

// Site Specific Colors
export const yellow = '#FACC02'
export const red = '#D56B33'
export const green = '#00C771'
export const blue = '#0078C8'
export const darkBlue = '#1E324F'
export const extraDarkBlue = darken(0.07, darkBlue)

// Basic Colors
export const transparent = 'transparent'
export const currentcolor = 'currentcolor'
export const bgColor = white
export const mainColor = blue
export const alert = red
export const notify = yellow
export const success = green
export const textColor = darkBlue
export const lightTextColor = rgba(textColor, 0.65)
export const lightGrey = '#F8F8F8'
export const hrColor = rgba(textColor, 0.1)

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)