import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import BasisWoff from '../assets/fonts/basis/BasisGrotesque-Regular.woff'
import BasisWoff2 from '../assets/fonts/basis/BasisGrotesque-Regular.woff2'

import BasisItalicWoff from '../assets/fonts/basis/BasisGrotesque-Italic.woff'
import BasisItalicWoff2 from '../assets/fonts/basis/BasisGrotesque-Italic.woff2'

import BasisBoldWoff from '../assets/fonts/basis/BasisGrotesque-Bold.woff'
import BasisBoldWoff2 from '../assets/fonts/basis/BasisGrotesque-Bold.woff2'

import BasisBoldItalicWoff from '../assets/fonts/basis/BasisGrotesque-BoldItalic.woff'
import BasisBoldItalicWoff2 from '../assets/fonts/basis/BasisGrotesque-BoldItalic.woff2'

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
	@font-face {
		font-family: '${ fontName }';
		src:  url('${ woff }') format('woff'),
					url('${ woff2 }') format('woff2');
		font-weight: ${ fontWeight };
		font-style: ${ fontStyle };
		font-display: swap;
	}
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const Basis = 'Basis'
export const BasisFont = fontFace(Basis, BasisWoff, BasisWoff2)
export const BasisItalicFont = fontFace(Basis, BasisItalicWoff, BasisItalicWoff2, 'normal', 'italic')
export const BasisBoldFont = fontFace(Basis, BasisBoldWoff, BasisBoldWoff2, '600')
export const BasisBoldItalicFont = fontFace(Basis, BasisBoldItalicWoff, BasisBoldItalicWoff2, '600', 'italic')
