import * as mq from './mediaQueries'
import { rgba } from 'polished'
import * as colors from './colors'
import { responsiveStyles } from './util'

import './fonts'

// Place global Typography in this file
export const primaryFont = `Basis, -apple-system, serif`
export const secondaryFont = `Basis, -apple-system, serif`
export const medium = 600
export const bold = 600

export const inlineCaps = `
	code {
		font-size: .8em;
		font-family: ${ primaryFont };
		font-weight: ${ bold };
		line-height: 1.5em;
		letter-spacing: .1em;
		text-transform: uppercase;
		color: ${ colors.mainColor };
	}
	i code {
		font-style: normal;
		color: ${ colors.textColor };
	}
`

export const bodyLarge = `
	${ responsiveStyles('font-size', 24, 22, 20, 18) }
	line-height: 1.5em;
	font-family: ${ primaryFont };
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
	${ inlineCaps }
`
export const bodyMedium = `
	${ responsiveStyles('font-size', 20, 18, 18, 16) }
	line-height: 1.5em;
	font-family: ${ primaryFont };
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
	${ inlineCaps }
`
export const body = `
	${ responsiveStyles('font-size', 18, 16, 16, 14) }
	line-height: 1.5em;
	font-family: ${ primaryFont };
	letter-spacing: 0;
	text-transform: none;
	font-weight: normal;
	${ inlineCaps }
`
export const bodySmall = `
	${ responsiveStyles('font-size', 16, 14, 14, 13) }
	line-height: 1.5em;
	font-family: ${ primaryFont };
	letter-spacing: .03;
	text-transform: none;
	font-weight: normal;
	${ inlineCaps }
`

export const h1 = `
	${ responsiveStyles('font-size', 56, 46, 42, 32) }
	line-height: 1.25em;
	font-family: ${ primaryFont };
	font-weight: ${ bold };
	letter-spacing: -.02em;
	text-transform: none;
`

export const h2 = `
	${ responsiveStyles('font-size', 50, 40, 36, 28) }
	line-height: 1.25em;
	font-family: ${ primaryFont };
	font-weight: ${ bold };
	letter-spacing: -.02em;
	text-transform: none;
`

export const h3 = `
	${ responsiveStyles('font-size', 42, 36, 32, 24) }
	line-height: 1.3em;
	font-family: ${ primaryFont };
	font-weight: ${ bold };
	letter-spacing: -.015em;
	text-transform: none;
`

export const h4 = `
	${ responsiveStyles('font-size', 32, 24, 22, 20) }
	line-height: 1.4em;
	font-family: ${ primaryFont };
	font-weight: ${ bold };
	letter-spacing: -.01em;
	text-transform: none;
`

export const h5 = `
	${ responsiveStyles('font-size', 24, 20, 20, 18) }
	font-family: ${ primaryFont };
	font-weight: ${ bold };
	line-height: 1.5em;
	letter-spacing: -.01em;
	text-transform: none;
`
export const h6 = `
	${ responsiveStyles('font-size', 16, 14, 14, 13) }
	font-family: ${ primaryFont };
	font-weight: ${ bold };
	line-height: 1.5em;
	letter-spacing: .1em;
	text-transform: uppercase;
`

export const blockquote = `
	${ bodyLarge }
	font-style: normal;
`

export const eyebrow = `
	${ bodyMedium }
	font-weight: ${ medium };
	color: ${ colors.blue };
`

export const buttonStyle = `
	${ body }
	${ responsiveStyles('font-size', 16, 16, 16, 14) }
	font-weight: ${ medium };
	line-height: 1em;
`

export const storyNotes = `
	max-width: 750px;
	p {
		code {
			background: ${ rgba(colors.textColor, 0.1) };
			color: ${ colors.textColor };
			border-radius: 3px;
			padding: .05em .35em .15em;
			font-style: normal;
		}
	}
`
